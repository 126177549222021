@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.indicators > div {
  background-color: #c0cbd8;
}

button.active-indicator {
  background-color: #30f;
  opacity: 1;
}

// ProductCardListItem
.carousel-wrapper {
  padding: 0;
  margin-bottom: 24px;
}

.carousel-container {
  gap: 16px;
  width: 99%;
}

.card-product-productcard {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.carousel-item:last-child {
  margin-right: fn.spacing(11);
}
